<template>
  <main class="home" role="main">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__header main__header--secondary">
      <div class="toper__item left">
        <mq-layout :mq="['md', 'lg', 'xlg', 'xl']" class="small-buttons">
          <!-- <a class="btn btn--small" href="#" @click.prevent="callCenter = !callCenter" :class="{ active: callCenter }">
            <PhoneIcon />
          </a>
          <a class="btn btn--small" href="#" @click.prevent="internet = !internet" :class="{ active: internet }">
            <InternetIcon />
          </a> -->
        </mq-layout>
      </div>
      <div class="toper__item">
        <div class="button-group">
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'today' }"
            @click="setSortType('today')"
          >
            <span>Сегодня</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'tomorrow' }"
            @click="setSortType('tomorrow')"
          >
            <span>Вчера</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'week' }"
            @click="setSortType('week')"
          >
            <span>Неделя</span>
          </button>
          <button
            type="button"
            :class="{ active: $store.state.data.trades.sort2 === 'month' }"
            @click="setSortType('month')"
          >
            <span>Месяц</span>
          </button>
          <button
            type="button"
            @click="openPeriod = true"
            :class="{
              active:
                $store.state.data.trades.sort2 === 'period' &&
                $store.state.data.trades.sortDate,
            }"
            ref="buttonPeriod"
            v-click-outside="vcoConfig"
            class="widgets__table__field"
          >
            <i>
              <CalendarIcon />
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker
                v-model="$store.state.data.trades.sortDate"
                ref="pickerPeriod"
                inline
                range
              />
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item right">
        <mq-layout :mq="['xs', 'sm', 'xsm']" class="small-buttons">
          <a class="btn btn--small" href="#">
            <PhoneIcon />
          </a>
          <a class="btn btn--small" href="#">
            <InternetIcon />
          </a>
        </mq-layout>
        <div class="small-buttons">
          <a class="btn btn--small btn--white" href="#">
            <i>
              <RefreshIcon />
            </i>
          </a>
        </div>
      </div>
    </div>
    <div class="main__content">
      <div class="tbl">
        <div class="tbl__header" v-if="$store.state.data.trades.showFilter">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="
                  filterDropName.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                "
                placeholder="Сотрудник"
                @input="onUserChange"
                :value="
                  filter.responsible_user_id
                    ? {
                        value: filter.responsible_user_id,
                        label: filterDropName.find(
                          (s) => s.id === filter.responsible_user_id
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="
                  filterDropGroup.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                "
                placeholder="Подразделение"
                @input="onGroupChange"
                :value="
                  filter.group_id
                    ? {
                        value: filter.group_id,
                        label: filterDropGroup.find(
                          (s) => s.id === filter.group_id
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
          </div>
          <div class="tbl__settings">
            <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
              <SettingsIcon/>
            </a>
          </div>
        </div>
        <!-- <mq-layout :mq="['lg', 'xlg', 'xl']"> -->
        <vue-good-table
          max-height="65vh"
          :columns="$store.state.data.analytics.columns"
          :rows="
            reportData.length
              ? [
                  {
                    name: 'Итого',
                    ...totals,
                    children: reportData,
                  },
                ]
              : []
          "
          styleClass="vgt-table"
          :group-options="{
            enabled: true,
            headerPosition: 'bottom',
          }"
          :pagination-options="{
            enabled: true,
            perPage: 15,
            nextLabel: '',
            prevLabel: '',
            rowsPerPageLabel: 'Вывести',
            ofLabel: 'из',
            pageLabel: 'страниц',
            allLabel: 'Все',
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- <router-link :to="{ name: 'home', params: { id: props.row._id } }" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link> -->
            <span
              v-if="props.column.field.includes('Summ')"
              v-tooltip:bottom="`${props.column.label} / ${props.row.name}`"
            >{{
              prettifyPrice(props.row[props.column.field])
            }}</span>
            <span
              v-else
              v-tooltip:bottom="`${props.column.label} / ${props.row.name}`"
            >{{ props.formattedRow[props.column.field] || '-' }}</span>
          </template>
          <div slot="emptystate">
            <mq-layout v-if="$apollo.loading" :mq="['lg', 'xlg', 'xl']">
              <div
                v-for="row in $store.state.me.role === 'manager' ? 1 : 5" :key="row + 100"
                style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; height: 70px"
              >
                <div class="sceleton" v-for="(block, index) in $store.state.data.analytics.columns.filter(elem => !elem.hidden).length" :key="index"
                >
                  <span style="opacity: 0">{{ $store.state.data.analytics.columns.filter(elem => !elem.hidden)[index].label }}</span>
                </div>
              </div>
            </mq-layout>
            <div v-else>Данных нет</div>
            <mq-layout :mq="['sm', 'xsm']">
              <div v-if="$apollo.loading" class="messages__status">Идёт загрузка данных...</div>
              <div v-else>Данных нет</div>
            </mq-layout>
          </div>
        </vue-good-table>
        <!-- </mq-layout> -->
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
import RefreshIcon from '../components/svg/RefreshIcon'
import CalendarIcon from '../components/svg/CalendarIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import gql from 'graphql-tag'
// Widgets
import PhoneIcon from '../components/svg/phone'
import InternetIcon from '../components/svg/InternetIcon'
import DownIcon from '@/components/svg/DownIcon'
import SettingsIcon from '@/components/svg/settings'

const _moment = extendMoment(moment)
const Today = _moment()
const Yesterday = _moment().subtract(1, 'days')
const NextWeek = _moment().endOf('week').add(1, 'week')

export default {
  name: 'analytics',
  components: {
    InternetIcon,
    PhoneIcon,
    CalendarIcon,
    RefreshIcon,
    DownIcon,
    SettingsIcon,
    DatePicker
  },
  data () {
    return {
      title: 'Аналитика',
      filterDropName: [],
      filterDropGroup: [],
      filter: {
        responsible_user_id: null,
        group_id: null
      },
      callCenter: false,
      internet: false,
      openPeriod: false,
      reportData: {},
      sortFilter: {},
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      }
    }
  },
  watch: {
    '$store.state.data.trades.sortDate' () {
      if (this.$store.state.data.trades.sortDate) {
        this.$store.state.data.trades.sort2 = 'period'
      } else {
        this.$store.state.data.trades.sort2 = null
      }
      this.openPeriod = false
    }
  },
  computed: {
    totals () {
      if (!Array.isArray(this.reportData)) return {}
      const res = {}
      this.$store.state.data.analytics.columns.filter(el => el.type === 'number').map(el => {
        res[el.field] = 0
      })
      this.reportData.reduce((acc, ent) => {
        for (const el in acc) {
          if (ent[el]) acc[el] += +ent[el]
        }
        return acc
      }, res)
      res.convLeadProject = res.leadsCount === 0 ? 0 : res.projectCount / res.leadsCount
      res.convProjectFact = res.projectCount === 0 ? 0 : res.factCount / res.projectCount
      res.convLeadFact = res.leadsCount === 0 ? 0 : res.factCount / res.leadsCount
      res.convSLeadProject = res.leadsSumm === 0 ? 0 : res.projectSumm / res.leadsSumm
      res.convSProjectFact = res.projectSumm === 0 ? 0 : res.factSumm / res.projectSumm
      res.convSLeadFact = res.leadsSumm === 0 ? 0 : res.factSumm / res.leadsSumm

      for (const el in res) {
        if (!el.includes('conv')) {
          res[el] = this.prettifyPrice(res[el])
        }
      }
      return res
    },
    getPeriodFilter () {
      switch (this.$store.state.data.trades.sort2) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today)
              .startOf('day')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Yesterday).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('week').format('YYYY-MM-DD'),
            end: _moment(Today)
              .endOf('week')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'next_week':
          return {
            start: _moment(NextWeek).startOf('week').format('YYYY-MM-DD'),
            end: _moment(NextWeek)
              .endOf('week')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today)
              .endOf('month')
              .add(1, 'days')
              .format('YYYY-MM-DD')
          }
        case 'after_next_week':
          return {
            start: _moment(NextWeek).endOf('week').format('YYYY-MM-DD')
          }
        case 'expired':
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.trades.sortDate[0])
              .startOf('day')
              .format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.trades.sortDate[1])
              .endOf('day')
              .format('YYYY-MM-DD')
          }
        default:
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
      }
    },
    getApolloFilter () {
      let filter = {}
      filter.responsible_user_id = this.filter.responsible_user_id
      filter.group_id = this.filter.group_id
      if (this.callCenter) filter.callCenter = true
      if (this.internet) filter.internet = true
      return filter
    }
  },
  apollo: {
    reportData () {
      return {
        query: gql`
          query($sort: PeriodFilter, $filter: JSON) {
            getReport(sort: $sort, filter: $filter)
          }
        `,
        variables () {
          return {
            sort: this.getPeriodFilter,
            filter: this.getApolloFilter
          }
        },
        update: (data) => {
          if (!this.filter.responsible_user_id) {
            this.filterDropName = data.getReport
              .filter((ent) => ent.user_id)
              .map((ent) => ({ name: ent.name, id: ent.user_id }))
          }
          if (!this.filter.group_id) {
            const res = {}
            data.getReport.forEach(el => {
              if (!res[el.group_id] && el.groupname) res[el.group_id] = { name: el.groupname, id: el.group_id }
            })
            this.filterDropGroup = Object.values(res)
            this.filterDropGroup.push({
              name: 'Филиалы',
              id: [
                '57a91d60-1c2e-11e2-8296-00304866b623',
                'dee2beee-4aac-11de-a104-00304866b623',
                '74cb0892-681c-11e9-bb4f-bcee7be31495',
                '1814a48e-afa1-11ea-80bf-00259047d1e4',
                'e5786ff6-4aac-11de-a104-00304866b623',
                '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
                'e578711c-4aac-11de-a104-00304866b623',
                'e57870a3-4aac-11de-a104-00304866b623',
                '9493c05d-4e93-11de-866b-001b38e49421',
                'e5787009-4aac-11de-a104-00304866b623',
                'dc096dea-feb3-11ec-80c9-00259047d1e4',
                '936c25c0-835c-11ec-80c6-00259047d1e4']
            })
            this.filterDropGroup.push({
              name: 'Филиалы Татарстан',
              id: [
                '57a91d60-1c2e-11e2-8296-00304866b623',
                'dee2beee-4aac-11de-a104-00304866b623',
                'e5787009-4aac-11de-a104-00304866b623',
                '936c25c0-835c-11ec-80c6-00259047d1e4',
                'c678408e-8ff2-11ed-80c9-00259047d1e4'
              ]
            })
            this.filterDropGroup.push({
              name: 'Филиалы не Татарстан',
              id: [
                '74cb0892-681c-11e9-bb4f-bcee7be31495',
                '1814a48e-afa1-11ea-80bf-00259047d1e4',
                'e5786ff6-4aac-11de-a104-00304866b623',
                '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
                'e578711c-4aac-11de-a104-00304866b623',
                'e57870a3-4aac-11de-a104-00304866b623',
                '9493c05d-4e93-11de-866b-001b38e49421',
                'dc096dea-feb3-11ec-80c9-00259047d1e4']
            })
            this.filterDropGroup.push({
              name: 'Казань офис продаж',
              id: ['245be5c7-26b6-11e9-bb4f-bcee7be31495', '32a431c7-26b6-11e9-bb4f-bcee7be31495']
            })
            // this.filterDropGroup.push({
            //   name: 'Дивизион РТ',
            //   id: ['dee2beee-4aac-11de-a104-00304866b623', 'e5787009-4aac-11de-a104-00304866b623']
            // })
            // this.filterDropGroup.push({
            //   name: 'Дивизион РБ',
            //   id: ['9493c05d-4e93-11de-866b-001b38e49421', 'e5786ff6-4aac-11de-a104-00304866b623', '3ae891c5-719c-11ee-80c9-00259047d1e4']
            // })
          }
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data.getReport
        }
      }
    }
  },
  methods: {
    setSortType (type) {
      if (this.$store.state.data.trades.sort2 === type) {
        this.$store.state.data.trades.sort2 = null
      } else {
        this.$store.state.data.trades.sort2 = type
      }
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    onUserChange (e) {
      if (e) {
        this.filter.responsible_user_id = e.value
      } else {
        this.filter.responsible_user_id = null
      }
    },
    onGroupChange (e) {
      if (e) {
        this.filter.group_id = e.value
      } else {
        this.filter.group_id = null
      }
    },
    switchColumnModal () {
      this.$store.state.data.analytics.columnModal = !this.$store.state.data.analytics.columnModal
    }
  }
}
</script>

<style lang="stylus" scoped>
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 40px
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;
</style>
